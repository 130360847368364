import './footer.scss';

import Form from '../Form';

import twitter from '../../assets/twitter.svg'
import discord from '../../assets/discord.svg'
import instagram from '../../assets/ig.svg'

const SOCIALS = [
  {
    id: 'twitter',
    icon: twitter,
    link: 'https://twitter.com/sfelderman1'
  },
  {
    id: 'discord',
    icon: discord,
    link: 'https://discord.gg/7jJXdBKuF5'
  },
  {
    id: 'instagram',
    icon: instagram,
    link: 'https://www.instagram.com/FeldermanStanley'
  }
];

const Footer = () => (
  <div className='footer'>
    <div className="footer__container">
      <h2>Join our <br/> community</h2>
      <Form />
    </div>
    <div className="footer__socials">
      {SOCIALS.map(({ id, icon, link }) => (
        <img key={id} src={icon} alt={id} onClick={() => window.open(link, '_blank')}/>
      ))
      }
    </div>
  </div>
);

export default Footer;

import { useForm } from '@formspree/react';
import './form.scss';
import { FORMSPREE } from "../../utils";

const Form = () => {
  const [state, handleSubmit] = useForm(FORMSPREE);

  return (
    <form onSubmit={handleSubmit}>
    {state.succeeded ? (
      <p>Thank you for subscribing!</p>
    ) : (
      <input type="email" name="email" placeholder="Enter email for PRE-SALE access" />
    )}
      <button type="submit">SUBMIT</button>
    </form>
  )
}

export default Form;

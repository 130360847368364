import './styles.scss';

import Hero from './components/Hero';
import Metablobs from './components/Metablobs';
import About from './components/About';
import Footer from './components/Footer';

export function App() {
  return (
    <>
      <Hero />
      <Metablobs />
      <About />
      <Footer />
    </>
  )
}

import React, { useState, useEffect, useContext } from 'react';
import { createClient, getDefaultClient } from '@formspree/core';

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

var FormspreeContext = React.createContext({
  client: undefined
});
FormspreeContext.displayName = 'Formspree';
var FormspreeProvider = function FormspreeProvider(props) {
  if (!props.project) {
    throw new Error('project is required');
  }

  var _useState = useState(function () {
    return createClient({
      project: props.project
    });
  }),
      _useState2 = _slicedToArray(_useState, 1),
      client = _useState2[0];

  useEffect(function () {
    client.startBrowserSession();
    return function () {
      client.teardown();
    };
  }, []);
  return React.createElement(FormspreeContext.Provider, {
    value: {
      client: client
    }
  }, props.children);
};
function useFormspree() {
  var _useContext = useContext(FormspreeContext),
      client = _useContext.client;

  return client || getDefaultClient();
}

var version = "2.2.4";

function isEvent(data) {
  return data.preventDefault !== undefined;
}

function useForm(formKey) {
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      submitting = _useState2[0],
      setSubmitting = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      succeeded = _useState4[0],
      setSucceeded = _useState4[1];

  var _useState5 = useState([]),
      _useState6 = _slicedToArray(_useState5, 2),
      errors = _useState6[0],
      setErrors = _useState6[1];

  var globalClient = useFormspree();
  var client = args.client || globalClient;

  if (!client) {
    throw new Error('You must provide a Formspree client');
  }

  if (!formKey) {
    throw new Error('You must provide a form key or hashid ' + '(e.g. useForm("myForm") or useForm("123xyz")');
  }

  var debug = !!args.debug;
  var extraData = args.data;

  var reset = function reset() {
    setSubmitting(false);
    setSucceeded(false);
    setErrors([]);
  };

  var handleSubmit = function handleSubmit(submissionData) {
    var getFormData = function getFormData(event) {
      event.preventDefault();
      var form = event.target;

      if (form.tagName != 'FORM') {
        throw new Error('submit was triggered for a non-form element');
      }

      return new FormData(form);
    };

    var formData = isEvent(submissionData) ? getFormData(submissionData) : submissionData;

    var appendExtraData = function appendExtraData(prop, value) {
      if (formData instanceof FormData) {
        formData.append(prop, value);
      } else {
        formData = Object.assign(formData, _defineProperty({}, prop, value));
      }
    }; // Append extra data from config


    if (_typeof(extraData) === 'object') {
      for (var prop in extraData) {
        if (typeof extraData[prop] === 'function') {
          appendExtraData(prop, extraData[prop].call(null));
        } else {
          appendExtraData(prop, extraData[prop]);
        }
      }
    }

    setSubmitting(true);
    return client.submitForm(formKey, formData, {
      endpoint: args.endpoint,
      clientName: "@formspree/react@".concat(version)
    }).then(function (result) {
      var status = result.response.status;
      var body;

      if (status === 200) {
        if (debug) console.log('Form submitted', result);
        setSucceeded(true);
        setErrors([]);
      } else if (status >= 400 && status < 500) {
        body = result.body;
        if (body.errors) setErrors(body.errors);
        if (debug) console.log('Validation error', result);
        setSucceeded(false);
      } else {
        if (debug) console.log('Unexpected error', result);
        setSucceeded(false);
      }

      return result;
    })["catch"](function (error) {
      if (debug) console.log('Unexpected error', error);
      setSucceeded(false);
      throw error;
    })["finally"](function () {
      setSubmitting(false);
    });
  };

  return [{
    submitting: submitting,
    succeeded: succeeded,
    errors: errors
  }, handleSubmit, reset];
}

var ValidationError = function ValidationError(props) {
  var prefix = props.prefix,
      field = props.field,
      errors = props.errors,
      attrs = _objectWithoutProperties(props, ["prefix", "field", "errors"]);

  var error = (errors || []).find(function (error) {
    return error.field == field;
  });

  if (!error) {
    return null;
  }

  return React.createElement("div", attrs, prefix, " ", error.message);
};

export { FormspreeProvider, ValidationError, useForm, useFormspree };

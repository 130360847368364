import './about.scss';

import stanley from '../../assets/about/stanley.png';

const About = () => (
  <div className='about__container'>
    <img className="about__img" src={stanley} alt="Stanley Felderman" />
    <div className="about__info">
      <h1>About the artist</h1>
      <hr />
      <p>
        Stanley Felderman heads the international Architectural/Interior Design firm,
        Felderman Keatinge + Associates, an industry pioneer, and future forward leader.
        In 2016, he was honored as the 37 th Annual Design Visionary and in 2018 he received
        the Circle of Excellence Award for his innovation including his concept design for the ATM.
        His work has been featured in the Museum of Modern Art and the Whitney Museum. Stanley’s clients
        include MTV Networks, Disney, Universal, Legendary Entertainment, Playboy, Mattel, Samsung, CAA, PDiddy,
        and Playboy.
      </p>
    </div>
  </div>
);

export default About;

import './hero.scss';

import logo from '../../assets/hero/logo.svg';
import vid from 'url:../../assets/hero/beatleap.mp4';
import poster from '../../assets/hero/poster.png';
import quotes from '../../assets/hero/quotes.png';

import Form from '../Form';

const Hero = () => (
  <div className='hero__container'>
    <img className='hero__logo' src={logo} alt="Stanley" />
    <div className='hero__flex'>
      <div className='hero__video'>
        <video
          src={vid}
          autoPlay loop muted playsInline
          poster={poster}
        ></video>
      </div>
      <div className='hero__info'>
        <img src={quotes} alt="background image" />
        <h1>ABOUT STANLEY</h1>
        <hr />
        <p>
          Stanley embodies willingness, openness and a sense of wonder while drawing us into
          a world of discovery. It’s an inspirational journey through the past, present and
          future.
        </p>
        <p>
          Join us, embrace all possibilities and discover the unexpected as Stanley travels through
          our physical world to the Metaverse. The community will participate and become an integral
          part of this journey.
        </p>

        <Form />
      </div>
    </div>
  </div>
);

export default Hero;

import './metablobs.scss';

import metablobs from '../../assets/metablobs/metablobs.png';

const Metabalobs = () => (
  <div className='metablobs'>
    <div className='metablobs__container'>
      <div className='metablobs__info'>
        <div>
          <h1 className='black'>The-Metablobs</h1>
          <hr className="black" />
          <p className="black">
            Stanley meets The-Metablobs, survivors of a toxic environment as they travel
            from the physical world through space and into the Metaverse.
          </p>
          <p className="black">
            Through these light-hearted humorous drawings there is a powerful
            and timely message of bonding and support of one another.
          </p>
          <p className="black">
            LIFE IS A JOURNEY, NOT A DESTINATION – BECOME PART OF THE ADVENTURE
          </p>
        </div>
      </div>
      <div className='metablobs__img'>
        <img src={metablobs} alt="Metablobs | Stanley" />
      </div>
    </div>
  </div>
);

export default Metabalobs;
